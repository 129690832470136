<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_09.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗摇头机--4G/images/QG5摇头机---4G_11.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗摇头机--4G",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>